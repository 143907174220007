import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface AnalyticsContextType {
  isAnalyticsEnabled: boolean;
}

// Define gtag function type
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
    [key: string]: any; // Allow indexing with strings for ga-disable properties
    __gsi_config?: {
      cookie_flags: string;
      cookie_update: boolean;
      cookie_expires: number;
      cookie_domain: string;
    };
  }
}

const AnalyticsContext = createContext<AnalyticsContextType>({ isAnalyticsEnabled: false });

export const useAnalytics = () => useContext(AnalyticsContext);

interface AnalyticsProviderProps {
  children: React.ReactNode;
}

const SENSITIVE_ROUTES = ['/login', '/profile', '/invoice_processor/privacy'];

// Analytics configuration
const GA4_MEASUREMENT_ID = 'G-B9FPPK93N8';
const GOOGLE_ADS_ID = 'AW-16676678730';

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({ children }) => {
  const location = useLocation(); 
  const [isAnalyticsEnabled, setIsAnalyticsEnabled] = useState(false);

  // Initialize consent mode v2 before anything else loads
  useEffect(() => {
    // Initialize dataLayer
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) {
      window.dataLayer.push(args);
    }
    window.gtag = gtag;

    // Set default consent mode configuration
    gtag('consent', 'default', {
      'ad_storage': 'denied',
      'analytics_storage': 'granted',
      'functionality_storage': 'granted',
      'personalization_storage': 'denied',
      'security_storage': 'granted',
      'wait_for_update': 500
    });

    // Configure first-party cookie settings
    const configScript = document.createElement('script');
    configScript.text = `
      window['ga-disable-${GA4_MEASUREMENT_ID}'] = false;
      window['ga-disable-${GOOGLE_ADS_ID}'] = true;
      
      // Configure first-party cookies
      window.__gsi_config = {
        cookie_flags: 'SameSite=Strict;Secure',
        cookie_update: true,
        cookie_expires: 63072000,
        cookie_domain: window.location.hostname
      };
    `;
    document.head.appendChild(configScript);

    return () => {
      document.head.removeChild(configScript);
    };
  }, []);

  useEffect(() => {
    // Check if current route is sensitive
    const isSensitivePage = SENSITIVE_ROUTES.some(route => location.pathname.startsWith(route));
    setIsAnalyticsEnabled(!isSensitivePage);
  }, [location]);

  // Load and configure analytics with proper consent
  useEffect(() => {
    if (!isAnalyticsEnabled) return;

    const loadAnalytics = async () => {
      // Load GA4 with enhanced privacy settings
      const ga4Script = document.createElement('script');
      ga4Script.async = true;
      ga4Script.src = `https://www.googletagmanager.com/gtag/js?id=${GA4_MEASUREMENT_ID}`;

      const configScript = document.createElement('script');
      configScript.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        // GA4 Configuration with enhanced privacy
        gtag('config', '${GA4_MEASUREMENT_ID}', {
          'cookie_domain': window.location.hostname,
          'cookie_flags': 'SameSite=Strict;Secure',
          'cookie_expires': 63072000,
          'allow_google_signals': false,
          'allow_ad_personalization_signals': false,
          'restricted_data_processing': true,
          'anonymize_ip': true,
          'client_storage': 'cookies',
          'first_party_collection': true,
          'send_page_view': true
        });

        // Google Ads Configuration
        gtag('config', '${GOOGLE_ADS_ID}', {
          'cookie_domain': window.location.hostname,
          'cookie_flags': 'SameSite=Strict;Secure',
          'cookie_expires': 63072000,
          'allow_google_signals': false,
          'allow_ad_personalization_signals': false,
          'restricted_data_processing': true,
          'anonymize_ip': true,
          'send_page_view': false
        });

        // Disable third-party cookies
        gtag('set', {
          'ads_data_redaction': true,
          'url_passthrough': true,
          'allow_ad_personalization_signals': false,
          'allow_google_signals': false
        });
      `;

      // Add scripts to head
      document.head.appendChild(ga4Script);
      document.head.appendChild(configScript);

      // Update consent with minimal necessary settings
      const updateConsent = () => {
        window.gtag('consent', 'update', {
          'ad_storage': 'denied',
          'analytics_storage': 'granted',
          'functionality_storage': 'granted',
          'personalization_storage': 'denied',
          'security_storage': 'granted'
        });

        // Enable GA4 after consent is updated
        window['ga-disable-' + GA4_MEASUREMENT_ID] = false;
        window['ga-disable-' + GOOGLE_ADS_ID] = false;
      };

      // Wait for GA4 to load then update consent
      ga4Script.onload = updateConsent;

      return () => {
        document.head.removeChild(ga4Script);
        document.head.removeChild(configScript);
      };
    };

    loadAnalytics();
  }, [isAnalyticsEnabled]);

  // Lazy load Yandex Metrika
  useEffect(() => {
    if (!isAnalyticsEnabled) return;

    const loadYandexMetrika = async () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.text = `
        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();
        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
        ym(98042431, "init", {
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true
        });
      `;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    loadYandexMetrika();
  }, [isAnalyticsEnabled]);

  return (
    <AnalyticsContext.Provider value={{ isAnalyticsEnabled }}>
      {children}
    </AnalyticsContext.Provider>
  );
}; 